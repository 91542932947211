import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'src/typography';
import { Button as _Button } from 'src/components/core';
import BasketSuccess from 'src/assets/basket-success-icon';
import useTranslation from 'hooks/use-translation';
import { PBB_NAME } from 'src/payments/constants';

type KioskOrderSuccessModalProps = {
  onClose: () => void;
};

export const KioskOrderSuccessModal = ({ onClose }: KioskOrderSuccessModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [count, setCount] = useState(8);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (count > 0) {
      const timerId = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
    onClose();
  }, [count, onClose]);

  return (
    <ModalBody>
      <Content>
        <BasketSuccess />
        <Heading tag='h1' size='medium'>
          Order submitted!
        </Heading>
        <MainText size='large'>
          {t(
            `kioskOrderSuccessModal.body`,
            `Your order has been submitted to the dispensary,
          please complete payment with ${PBB_NAME} and proceed
          to the counter when your order is ready.`
          )}
        </MainText>
        <Button fluid onClick={onClose} size='large'>
          {t(`kioskOrderSuccessModal.button`, `Close`)}
        </Button>
        <SubText size='small'>
          {t(`kioskOrderSuccessModal.footer`, `This window will automatically close in {{count}} seconds...`, {
            count,
          })}
        </SubText>
      </Content>
    </ModalBody>
  );
};

const ModalBody = styled.div`
  ${({ theme: { spaces } }) => css`
    width: 100%;
    height: 100%;
    padding: ${spaces[9]} ${spaces[8]};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const Content = styled.div`
  ${({ theme: { spaces } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${spaces[6]};
    max-width: 390px;
    text-align: center;
  `}
`;

const Heading = styled(Typography.Heading)`
  ${({ theme: { colors } }) => css`
    color: ${colors.primaryBlack};
  `}
`;

const MainText = styled(Typography.Body)`
  ${({ theme: { colors } }) => css`
    color: ${colors.grey[30]};
  `}
`;

const SubText = styled(Typography.Body)`
  ${({ theme: { colors } }) => css`
    color: ${colors.grey[40]};
  `}
`;

const Button = styled(_Button)`
  width: 80%;
  margin-bottom: -15px;
`;
