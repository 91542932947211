import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { paymentMethodsToDisplayNames, ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY } from 'shared/constants';
import { hasNonCashPaymentOptions } from 'shared/helpers/dispensaries';

import useStores from 'shared/hooks/use-stores';
import { useCheckout } from 'checkout/context';
import useErnie from 'shared/hooks/use-ernie';
import useEventListener from 'shared/hooks/use-event-listener';
import useDispensaryPaymentMethods from 'shared/hooks/use-dispensary-payment-methods';

import { ModalNames, useModals } from 'components/modals';

import { Image, RadioGroup, InfoBox, DividerWithText } from 'shared/components';

import { FiveDollarDiscountBanner, MemoizedPlaidEmbeddedSearch, PaySignUpButton } from 'src/payments/components';
import { useDutchiePay } from 'src/payments/hooks';
import ConnectHypur from 'checkout/components/connect-hypur';
import PaysafeForm from 'checkout/components/sections/payment/paysafe-form';
import ChaseHostedPaymentForm from 'checkout/components/sections/payment/chase/chase-hosted-payment-form';
import DutchiePay from 'checkout/components/sections/payment/dutchie-pay';
import { HostedTokenizationForm } from 'checkout/components/sections/payment/moneris/hosted-tokenization-form';
import BasisTheory from 'checkout/components/sections/payment/credit-card/basis-theory';
import { PaymentType, PlaidLinkCustomizationNames, PBB_NAME, RETHINK_PAY } from 'src/payments/constants';
import { useRouter } from 'next/router';
import useRethinkPay from 'src/payments/hooks/use-rethink-pay/use-rethink-pay';
import { useIncentives, UserType } from 'src/payments/hooks/use-incentives/use-incentives';

const paymentOptionIcons = {
  cash: '/icons/payment-methods/cash-icon.svg',
  check: '/icons/payment-methods/check-icon.svg',
  creditCard: '/icons/payment-methods/credit-card-icon.svg',
  debitCard: '/icons/payment-methods/credit-card-icon.svg',
  debit: '/icons/payment-methods/credit-card-icon.svg',
  alt36: '/icons/payment-methods/credit-card-icon.svg',
  linx: '/icons/payment-methods/linx-logo-icon.svg',
  payOnlineHypur: '/icons/payment-methods/hypur-logo-icon.svg',
  payOnlineMerrco: '/icons/payment-methods/credit-card-icon.svg',
  payOnlineMoneris: '/icons/payment-methods/credit-card-icon.svg',
  payOnlineChase: '/icons/payment-methods/credit-card-icon.svg',
  creditCardByPhone: '/icons/payment-methods/credit-card-icon.svg',
  creditCardAtDoor: '/icons/payment-methods/credit-card-icon.svg',
  rethinkPay: '/icons/payment-methods/credit-card-icon.svg',
  canPay: '/icons/payment-methods/canpay-logo-icon.svg',
  paytender: '/icons/payment-methods/paytender-logo-icon.svg',
  aeropay: '/icons/payment-methods/aeropay-logo-icon.svg',
};

const someCards = new Set(['debitCard', 'creditCard']);
const renderAdditionalPaymentInfo = (paymentMethod, dispensary, deliveryOption, requiresPrePaid) => {
  const { creditCardAtDoor, creditCardByPhone } = dispensary.paymentTypesAccepted;
  let infoText = '';

  if (
    someCards.has(paymentMethod) &&
    !requiresPrePaid &&
    ((paymentMethod === 'creditCard' && creditCardAtDoor) || paymentMethod === 'debitCard')
  ) {
    infoText = `No info needed, your card will be charged upon ${
      deliveryOption === ORDER_TYPE_DELIVERY ? ORDER_TYPE_DELIVERY : ORDER_TYPE_PICKUP
    }.`;
  } else if (paymentMethod === 'creditCardByPhone' && creditCardByPhone) {
    infoText = 'A member of our staff will contact you by phone to take your payment.';
  }
  if (infoText.length > 0) {
    return <InfoBox mt='14px'>{infoText}</InfoBox>;
  }

  return <div />;
};

export const DUTCHIE_PAY = 'dutchiePay';
export const HYPUR = 'payOnlineHypur';

function PaymentOptions(props) {
  const {
    setOpen = _.noop,
    onSave = _.noop,
    Summary = Fragment,
    Actions = Fragment,
    Container = Fragment,
    ...containerProps
  } = props;
  const showErnie = useErnie();
  const Checkout = useCheckout();
  const router = useRouter();
  const { openModal } = useModals();
  const flags = useFlags();

  const [paymentCardType, setPaymentCardType] = useState('');
  const { Cart, User, UI, Payments } = useStores();
  const { order, requiresPrePaid } = Cart;
  const { enrollmentStatus } = useRethinkPay();
  const { dispensary, paymentMethod, deliveryOption } = order;
  const allPaymentOptions = useDispensaryPaymentMethods();
  const [creditCardError, setCreditCardError] = useState('');

  const { showPaymentBanner, userType } = useIncentives();

  const plaidEmbeddeedSearchEnabled = flags['fintech.platform.dutchiepay.plaid-embedded-search'] ?? false;
  const showEmbeddedSearch = plaidEmbeddeedSearchEnabled && User.isLoggedIn;

  const {
    allowDutchiePayEnrollment,
    bankAccounts,
    isDutchiePayEnabledForDispo,
    isEnrolledDutchiePay,
  } = useDutchiePay();
  const paymentMethodId = bankAccounts?.[0]?.id ?? null;

  // In an outage, DP will be disabled but will still be listed as a payment option, so
  // we have to filter it out here.
  const paymentOptions = isDutchiePayEnabledForDispo
    ? allPaymentOptions
    : _.reject(allPaymentOptions, { value: DUTCHIE_PAY });

  const showDutchiePaySignup = allowDutchiePayEnrollment && _.some(paymentOptions, { value: DUTCHIE_PAY });

  const dutchiePayOption = isDutchiePayEnabledForDispo && Cart.order.paymentMethod === DUTCHIE_PAY;

  const hasMultiplePaymentOptions = paymentOptions.length > 1;

  const dutchiePayOnlyOption = paymentOptions.length === 1 && paymentOptions[0].value === DUTCHIE_PAY;

  // moved this out of the hook as it didn't have access to marketplace
  if (_.some(paymentOptions, { value: DUTCHIE_PAY })) {
    const dutchiePayPaymentOption = _.find(paymentOptions, { value: DUTCHIE_PAY });
    dutchiePayPaymentOption.LabelComponent = DutchiePay;
    dutchiePayPaymentOption.marginRight = '15px';
  }

  // if we have only 1 payment type that is merrco, hide other payment methods
  const hidePaymentOptions =
    paymentOptions.length === 1 &&
    paymentOptions[0].value === 'payOnlineMerrco' &&
    dispensary?.paymentTypesAccepted.payOnlineMerrco;

  const showHypurPaymentOption =
    Cart.order.paymentMethod === 'payOnlineHypur' && dispensary?.paymentTypesAccepted.payOnlineHypur;

  const creditCardPaymentOptionEnabled =
    dispensary?.paymentTypesAccepted.payOnlineMoneris ||
    dispensary?.paymentTypesAccepted.payOnlineMerrco ||
    dispensary?.paymentTypesAccepted.payOnlineChase ||
    dispensary?.paymentTypesAccepted.rethinkPay;

  const creditCardPaymentOptionToShow = Cart.order.paymentMethod;

  const acceptedCreditCardPaymentOptions = ['payOnlineMerrco', 'payOnlineMoneris', 'payOnlineChase'];

  let cardClosedCopy = 'Cash Only';
  if (hasNonCashPaymentOptions(dispensary)) {
    cardClosedCopy = paymentMethodsToDisplayNames[paymentMethod] || 'Select a payment method';
  }
  const IdNoticeContainerText = `You’ll be required to show your ID and credit card upon ${
    deliveryOption === 'delivery' ? 'delivery' : 'pickup'
  }.`;

  const qualifiedPBBUser = userType === UserType.QUALIFIED;

  useEffect(() => {
    if (!order.paymentMethod) {
      setOpen(true);
    }
  }, [order.paymentMethod]);

  useEffect(() => {
    // clear paysafe input fields just incase they are saved in localstorage from another checkout session
    // we never want to reuse a paysafe token or keep for longer than the current session.
    if (dispensary.paymentTypesAccepted.payOnlineMerrco) {
      Cart.paysafeInput = { token: null, zip: null };
    }

    // clear monerisInput just in case any previous checkouts populated this. this object stores the
    // user's tokenized payment method and shouldn't be used beyond the intended transaction
    if (dispensary.paymentTypesAccepted.payOnlineMoneris) {
      Cart.monerisInput = {};
    }

    // open if more than one option available
    if (paymentOptions.length > 1 && _.isNil(Cart.hypurAccessToken)) {
      setOpen(true);
    }

    if (
      paymentOptions.length === 1 &&
      dispensary.paymentTypesAccepted.payOnlineHypur &&
      _.isNil(Cart.hypurAccessToken)
    ) {
      Cart.setPaymentMethod('payOnlineHypur');
      setOpen(true);
    } else if (Cart.hypurAccessToken && dispensary.paymentTypesAccepted.payOnlineHypur) {
      Cart.setPaymentMethod('payOnlineHypur');
      setOpen(false);
    }

    // open if payment processor is the only option because we need details from user
    if (
      paymentOptions.length === 1 &&
      (dispensary.paymentTypesAccepted.payOnlineMerrco || dispensary.paymentTypesAccepted.creditCardByPhone)
    ) {
      const newPaymentMethod = dispensary.paymentTypesAccepted.payOnlineMerrco
        ? 'payOnlineMerrco'
        : 'creditCardByPhone';
      Cart.setPaymentMethod(newPaymentMethod);
      setOpen(true);
    } else if (paymentOptions.length === 1) {
      // if we've made it this far and still only have one payment option available, set it and close.
      Cart.setPaymentMethod(paymentOptions[0].value);
      setOpen(false);
    }
  }, []);

  const paymentError = Checkout.getError({ path: 'payment' }).error;
  useEffect(() => {
    if (paymentError) {
      setCreditCardError(paymentError.message);
      setOpen(true);
    }
  }, [setOpen, paymentError]);

  // Determine Payment section status for initial view
  // Scenario 1. PBB Enabled, User Enrolled: PBB selected, panel is closed
  // Scenario 2.a PBB Enabled, User Unenrolled, Has non-PBB options: First non-PBB option selected, panel is open
  // Scenario 2.b PBB Enabled, User Unenrolled, PBB only option: pBB is selected, panel is open
  // Scenario 3. PBB Not Enabled: First option selected, panel is open
  useEffect(() => {
    if (isDutchiePayEnabledForDispo) {
      if (isEnrolledDutchiePay) {
        Cart.setPaymentMethod(DUTCHIE_PAY);
        Cart.setPaymentMethodId(paymentMethodId);
        setOpen(false);
      } else if (!User.exists || (User.exists && !isEnrolledDutchiePay)) {
        // if dutchiePay is the only option - paymentOptions[1] does not exist
        const validOption = hasMultiplePaymentOptions ? paymentOptions[1] : paymentOptions[0];

        Cart.setPaymentMethod(validOption.value);
        setOpen(true);
      }
      Cart.setIsFirstTimePBBUser(qualifiedPBBUser);
    } else {
      Cart.setPaymentMethod(paymentOptions[0].value);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cart, isDutchiePayEnabledForDispo, isEnrolledDutchiePay, User.exists, setOpen, qualifiedPBBUser]);

  useEffect(() => {
    if (isDutchiePayEnabledForDispo && isEnrolledDutchiePay) {
      Cart.setPaymentMethod(DUTCHIE_PAY);
      Cart.setPaymentMethodId(paymentMethodId);
      Cart.setIsFirstTimePBBUser(qualifiedPBBUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryOption, qualifiedPBBUser]);

  function onChoiceChange(value) {
    Cart.setPaymentMethod(value);
    Cart.removeTip();

    if (value === DUTCHIE_PAY) {
      Cart.setPaymentMethodId(paymentMethodId);
      Cart.setIsFirstTimePBBUser(qualifiedPBBUser);
    } else {
      Cart.setPaymentMethodId(null);
    }
  }

  function savePayment() {
    if (!paymentMethod || paymentMethod === '') {
      showErnie('Please select a payment method', 'danger');
    } else {
      Cart.setPaymentMethod(paymentMethod);
      setOpen(false);
      Checkout.removeError({ match: 'payment' });
    }
  }

  function handleSetPaysafeToken(token, zip, cardType) {
    Cart.paysafeInput = { token, zip };
    setPaymentCardType(cardType);
    savePayment();
  }

  const handleHypurAccessTokenFromEmbedded = useCallback((event) => {
    const { key, newValue } = event;

    if (key === 'embedded-hypur-access-token' && !_.isNil(newValue)) {
      setOpen(false);
      Cart.hypurAccessToken = newValue;
      window.localStorage.removeItem('embedded-hypur-access-token');
    }
  }, []);

  useEventListener('storage', handleHypurAccessTokenFromEmbedded);

  const filteredPaymentOptions = _.reject(
    paymentOptions,
    ({ value }) =>
      (value === DUTCHIE_PAY && !isEnrolledDutchiePay) || (value === RETHINK_PAY && !Payments.showRethinkPay)
  );

  useEffect(() => {
    if (enrollmentStatus) {
      const { paymentTypes } = enrollmentStatus.listRetailerPaymentTypesStatus;
      paymentTypes.map((paymentType) => {
        if (paymentType.type === PaymentType.PAYMENT_TYPE_RETHINK && !paymentType.enabled) {
          Payments.setShowRethinkPay(false);
          Cart.setPaymentMethod(filteredPaymentOptions[0].value);
        } else if (paymentType.type === PaymentType.PAYMENT_TYPE_RETHINK && paymentType.enabled) {
          Payments.setShowRethinkPay(true);
        }
        return null;
      });
    }
  }, [Cart, enrollmentStatus, filteredPaymentOptions]);

  // eslint-disable-next-line react/destructuring-assignment
  const displayDutchiePaySelected = !props.expanded && Cart.paymentMethod === 'dutchiePay';
  const summaryStyle = dutchiePayOnlyOption ? { cursor: 'default' } : {};

  return (
    <>
      {showPaymentBanner && <FiveDollarDiscountBanner marginBottom={!showDutchiePaySignup ? 0 : '-16px'} />}
      <Container
        hideToggles={dutchiePayOnlyOption}
        onSave={onSave}
        {...containerProps}
        collapsedSize={displayDutchiePaySelected && 'large'}
      >
        <Summary style={summaryStyle}>
          {dutchiePayOption && <DutchiePay />}
          {!dutchiePayOption && (
            <Flex alignItems='center'>
              <Image
                alt=''
                src={!order.paymentMethod ? '/icons/dollar-sign-icon.svg' : paymentOptionIcons[order.paymentMethod]}
                width={!order.paymentMethod ? '20px' : '26px'}
                height='26px'
                ml={!order.paymentMethod ? '3px' : '0'}
                mr={!order.paymentMethod ? '18px' : '15px'}
                pt='1px'
                pb='1px'
              />

              <Flex alignItems='baseline'>
                <Text fontSize={14} color='#5d666d' fontWeight='bold'>
                  {cardClosedCopy}
                </Text>

                {Cart.order.paymentMethod === 'payOnlineMerrco' && (
                  <CardText fontSize={14} color='#5d666d'>
                    &nbsp;-&nbsp;{paymentCardType}
                  </CardText>
                )}
                {Cart.hypurAccessToken && order.paymentMethod === 'payOnlineHypur' && (
                  <>
                    <Divider />
                    <Text fontSize={13} color='#26A27B' fontWeight='bold'>
                      Connected
                    </Text>
                  </>
                )}
              </Flex>
            </Flex>
          )}
        </Summary>

        <Actions>
          {showDutchiePaySignup && !Cart.isKioskOrder && (
            <>
              {showEmbeddedSearch ? (
                <PlaidSearchContainer>
                  <SingleOptionContainer marginLeft={12}>
                    <SingleOption>Pay by bank instantly</SingleOption>
                  </SingleOptionContainer>
                  <MemoizedPlaidEmbeddedSearch
                    handleEmbeddedDutchiePayLink={() => openModal(ModalNames.termsOfService)}
                    linkCustomizationName={PlaidLinkCustomizationNames.EMBEDDED_SEARCH_ONLINE}
                  />
                </PlaidSearchContainer>
              ) : (
                <PaySignUpButton
                  cname={Cart.dispensary.cName}
                  enrollmentSource={`checkout-page-${UI?.variant}`}
                  location='payment-section'
                />
              )}
              {hasMultiplePaymentOptions && !showEmbeddedSearch && <DutchiePayDivider>OR</DutchiePayDivider>}
              {!hasMultiplePaymentOptions && (
                <>
                  <DutchiePaySpacer />
                  <DutchiePayEnrollPrompt>
                    {`This dispensary only accepts ${PBB_NAME} for online orders. Click the button above to sign up.`}
                  </DutchiePayEnrollPrompt>
                </>
              )}
            </>
          )}
          {hasNonCashPaymentOptions(dispensary) && (
            <Box mb='10px' width='100%'>
              <Box mb='14px'>
                {!hidePaymentOptions && (
                  <RadioGroup
                    options={Cart.isKioskOrder ? _.reject(paymentOptions, { disabled: true }) : filteredPaymentOptions}
                    hasSublabels
                    display='block'
                    checked={paymentMethod}
                    onChange={onChoiceChange}
                    mb='12px'
                    name='paymentType'
                    required
                  />
                )}

                {hidePaymentOptions && (
                  <SingleOptionContainer>
                    <SingleOption>{paymentOptions[0].label}</SingleOption>
                    <SingleSubLabel>{paymentOptions[0].sublabel}</SingleSubLabel>
                  </SingleOptionContainer>
                )}

                {Payments.showRethinkPay && Cart.paymentMethod === RETHINK_PAY && !router.query.rethink_token && (
                  <BasisTheory onSave={onSave} />
                )}

                {creditCardPaymentOptionEnabled && (
                  <CreditCardPaymentOptionContainer
                    variant={_.includes(acceptedCreditCardPaymentOptions, creditCardPaymentOptionToShow)}
                  >
                    {!creditCardError && <IdNoticeContainer>{IdNoticeContainerText}</IdNoticeContainer>}
                    {creditCardError && <ErrorMessage>{creditCardError}</ErrorMessage>}
                    {dispensary?.paymentTypesAccepted.payOnlineMerrco && (
                      <PaysafeForm
                        merrcoPublicToken={dispensary.merrcoPublicToken}
                        onToken={handleSetPaysafeToken}
                        hideSave={Cart.isKioskOrder}
                        handleError={setCreditCardError}
                      />
                    )}
                    {dispensary?.paymentTypesAccepted.payOnlineMoneris && (
                      <HostedTokenizationForm onSave={onSave} handleError={setCreditCardError} />
                    )}
                    {dispensary?.paymentTypesAccepted.payOnlineChase && <ChaseHostedPaymentForm onSave={onSave} />}
                  </CreditCardPaymentOptionContainer>
                )}
                {showHypurPaymentOption && <ConnectHypur dispensaryId={dispensary.id} />}
              </Box>
            </Box>
          )}
        </Actions>
      </Container>
    </>
  );
}

export default observer(PaymentOptions);

const ErrorMessage = styled.div`
  background-color: #fff0f0;
  border: 1px solid #d8c1c1;
  align-items: center;
  font-size: 13px;
  padding: 12px 8px 9px 15px;
  margin-bottom: 1em;
  border-radius: 4px;
  line-height: 20px;
  color: #8e5656;
`;
const CreditCardPaymentOptionContainer = styled.div`
  ${(props) => (props.variant ? `display: inline-block;` : `display: none;`)}
`;

const IdNoticeContainer = styled.div`
  background-color: #f0f7f0;
  border: 1px solid #c5ddd3;
  align-items: center;
  font-size: 13px;
  padding: 12px 8px 9px 15px;
  margin-bottom: 1em;
  border-radius: 4px;
  line-height: 20px;
  color: #6d8d76;
`;

const Divider = styled.div`
  height: 10px;
  background-color: #848f97;
  width: 1px;
  margin: 0 9px;
`;

const SingleOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0 17px 0;

  ${space}
`;

const SingleOption = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-right: 6px;
  color: #6d747b;
`;

const SingleSubLabel = styled.div`
  font-size: 14px;
  color: #6d747b;
`;

const CardText = styled(Text)`
  text-transform: capitalize;
`;

const DutchiePayDivider = styled(DividerWithText)`
  margin: 17px -21px 21px;
`;

const DutchiePaySpacer = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors?.greyBorder}`};
  width: 100%;
  height: 20px;
`;

const DutchiePayEnrollPrompt = styled.div`
  color: ${({ theme }) => theme.colors?.grey[60]};
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  margin-top: 20px;
  height: 40px;
  margin-bottom: -20px;
`;

const PlaidSearchContainer = styled.div`
  ${({ theme: { colors, spaces } }) => css`
    background-color: ${colors.grey[95]};
    margin: -22px;
    margin-bottom: ${spaces[5]};
    padding: 10px 10px 20px;
    border-radius: 3px 3px 0 0;
    border: 1px solid ${colors.grey[90]};
    border-bottom-color: transparent;
  `}
`;
